<template>
  <div>
    <form class="pure-form" @submit.prevent="addComment">
      <legend>Comment on this Photo</legend>
      <fieldset>
        <textarea
          v-model="comment"
          placeholder="Write your comment here"
        ></textarea>
      </fieldset>
      <p v-if="error" class="error">{{ error }}</p>
      <fieldset class="buttons">
        <button type="submit" class="pure-button pure-button-primary right">
          Comment
        </button>
      </fieldset>
    </form>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddComment",
  data() {
    return {
      error: "",
    };
  },
  props: {
    photoID: String,
  },
  methods: {
    async addComment() {
      if (this.comment != "") {
        try {
          this.error = "";
          await axios.post("/api/comments/" + this.photoID, {
            comment: this.comment,
          });
          this.$emit("commentAdded");
        } catch (error) {
          this.error = "Error: " + error.response.data.message;
        }
      }
    },
    resetTextField() {},
  },
};
</script>
