<template>
  <div>
    <div class="singlePhotoContainer">
      <img :src="photo.path" class="singlePhoto" />
      <div class="photoInfo">
        <p class="photoTitle">{{ photo.title }}</p>
      </div>
      <p class="photoDate">
        {{ photo.user.firstName }} {{ photo.user.lastName }}
      </p>
      <p class="photoDate">{{ formatDate(photo.created) }}</p>
      <p class="photoDate">{{ photo.description }}</p>
    </div>
    <AddComment v-if="user" :photoID="photo._id" @commentAdded="getComments" />
    <div class="commentsContainer">
      <div class="comment" v-for="comment in comments" v-bind:key="comment._id">
        <hr />
        <h4>
          {{ comment.user.firstName }} {{ comment.user.lastName }} --
          {{ formatDate(comment.created) }}
        </h4>
        <p>{{ comment.comment }}</p>
      </div>
    </div>
    <p v-if="error">{{ error }}</p>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import AddComment from "@/components/AddComment.vue";
export default {
  name: "Photo-comp",
  data() {
    return {
      photo: "",
      comments: "",
      error: "",
    };
  },
  components: {
    AddComment,
  },
  created() {
    this.getPhoto();
    this.getUser();
    this.getComments();
  },
  computed: {
    user() {
      return this.$root.$data.user;
    },
  },
  methods: {
    async getPhoto() {
      try {
        let response = await axios.get("/api/photo/" + this.$route.params.id);
        this.photo = response.data;
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async getComments() {
      try {
        let response = await axios.get(
          "/api/comments/" + this.$route.params.id
        );
        this.comments = response.data;
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
    async getUser() {
      try {
        let response = await axios.get("/api/users");
        this.$root.$data.user = response.data.user;
      } catch (error) {
        this.$root.$data.user = null;
      }
    },
    formatDate(date) {
      if (moment(date).diff(Date.now(), "days") < 15)
        return moment(date).fromNow();
      else return moment(date).format("d MMMM YYYY");
    },
  },
};
</script>
<style scoped>
.singlePhoto {
  margin-top: 120px;
}

.photoInfo {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.photoInfo p {
  margin: 0px;
}

.photoDate {
  font-size: 0.7em;
  font-weight: normal;
}

.singlePhotoContainer {
  margin-bottom: 40px;
}

.commentsContainer {
  bottom-margin: 40px;
}

p {
  margin: 0px;
}

/* Masonry */
*,
*:before,
*:after {
  box-sizing: inherit;
}

.image-gallery {
  column-gap: 1em;
}

.image {
  margin: 0 0 1.5em;
  display: inline-block;
  width: 100%;
}

.image img {
  width: 100%;
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
  .image-gallery {
    column-count: 4;
  }
}

/* Masonry on medium-sized screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .image-gallery {
    column-count: 3;
  }
}

/* Masonry on small screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
  .image-gallery {
    column-count: 2;
  }
}
</style>
